import { Membership as MembershipWithUser } from 'src/components/GroupsDataGridCell/GroupsDataGridCell'

import { Membership } from '../MemberManagementCell/MemberManagementCell'

export const mapUsersData = (
  usersDataRaw: (Partial<Membership> & Partial<MembershipWithUser>)[],
) =>
  usersDataRaw.map((membership) => {
    return {
      id: membership.id,
      status: membership.isActive ? membership.user.status : 'INACTIVE',
      clientId: membership.clientId,
      role: membership.role,
      userId: membership.user.id,
      name: membership.user.name,
      email: membership.user.email,
      phone: membership.user.phone,
      userAgreementAccepted: membership.user.userAgreementAccepted,
      cognitoUserStatus: membership.user.cognitoUserStatus,
      invitedAt: membership.user.invitedAt,
      updatedAt: membership.updatedAt,
      deactivatedAt: membership.deactivatedAt ? membership.deactivatedAt : null,
      position: membership.user.position,
      memberPositions: membership.memberPositions,
      avatarUrl: membership.user.avatarUrl,
      avatarStorageObject: membership.user.avatarStorageObject,
      personalInfoData: membership.personalInfoData,
      reportsToId: membership.reportsTo?.id,
      reportsToName: membership.reportsTo?.user?.name,
      membershipGroups: membership.membershipGroups,
      membershipGroupsText: membership.membershipGroups
        ?.map((group) => group.membershipGroup?.name)
        .join(' '),
      userRole: membership.user?.role,
      cognitoHasMFAEnabled: membership.user.cognitoHasMFAEnabled,
      isSSOUser: membership.user.cognitoUserStatus === 'EXTERNAL_PROVIDER',
    }
  })

export type UserData = ReturnType<typeof mapUsersData>[0]
